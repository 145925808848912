<template>
  <v-card class="bgMinsal" elevation="0">
    <v-data-table
      :headers="headers"
      :items="dataInsumo[index].listado_insumos"
      :items-per-page="5"
      :loading="false"
      hide-default-footer
      loading-text="Cargando insumos . . ."
      mobile-breakpoint="1280"
      no-data-text="Sin insumos"
    >
      <!-- <template #[`item.`]="{ item }"> </template> -->
      <template #[`item.cantidad_aprobar`]="{ item }">
        <v-text-field
          class="pt-3"
          v-model.number="item.cantidad_aprobar"
          hide-details="auto"
          dense
          outlined
          v-if="item.estado"
          maxlength="7"
          :rules="[validNumberInput, validMoreThanCant(item)]"
          @blur="
            item.total_local = item.cantidad_aprobar * item.precio_unitario
          "
        />
      </template>
      <template #[`item.total_local`]="{ item }">
        <span> {{ item.total_local }} </span>
      </template>
      <template #[`item.enlace_compra`]="{ item }">
        <a :href="item.enlace_compra" target="_blank">
          {{ item.enlace_compra }}
        </a>
      </template>
      <template #[`item.estado`]="{ item }">
        <v-icon v-if="item.estado" color="green darken-2">
          mdi-check-circle-outline
        </v-icon>
        <span v-else> Sin aprobación </span>
      </template>
      <template #[`item.acciones`]="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-checkbox
              v-bind="attrs"
              v-on="on"
              v-model="item.estado"
              @click="item.cantidad_aprobar = null"
            />
          </template>
          <span> Cambiar estado </span>
        </v-tooltip>
      </template>
    </v-data-table>
    <AppPaginationComponent />
  </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "TableInsumosEPComponent",
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    headers: [
      {
        text: "Correlativo",
        align: "center",
        value: "correlativo",
      },
      {
        text: "Mercancia",
        align: "center",
        value: "mercancia",
      },
      {
        text: "Unidad de Medida",
        align: "center",
        value: "unidad_medida",
      },
      // {
      //   text: "Mes",
      //   align: "center",
      //   value: "mes",
      // },
      {
        text: "Cantidad",
        align: "center",
        value: "cantidad",
      },
      {
        text: "Precio Unitario ($)",
        align: "center",
        value: "precio_unitario",
      },
      {
        text: "Total ($)",
        align: "center",
        value: "total_global",
      },
      {
        text: "Cantidad Aprobar",
        align: "center",
        value: "cantidad_aprobar",
        sortable: false,
      },
      {
        text: "Total ($)",
        align: "center",
        value: "total_local",
      },
      {
        text: "Enlace de compra",
        align: "center",
        value: "enlace_compra",
        sortable: false,
      },
      {
        text: "Estado",
        align: "center",
        value: "estado",
      },
      {
        text: "Acciones",
        align: "center",
        value: "acciones",
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapState("solicitudesCompraModule", ["dataInsumo"]),
  },
  methods: {
    validNumberInput(value) {
      if (value) {
        const regNumber = /^[0-9]+$/;
        if (!regNumber.test(value)) {
          return "Solo se permiten números";
        } else {
          return true;
        }
      } else {
        return "El campo es requerido";
      }
    },
    validMoreThanCant(item) {
      return (value) => {
        if (value) {
          if (value > item.cantidad) {
            return "La cantidad aprobada es mayor a la cantidad solicitada";
          } else {
            return true;
          }
        } else {
          return true;
        }
      };
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.v-text-field) {
  min-width: 150px;
  max-width: 225px;
}
</style>