<template>
  <!-- agregar [ multiple ] si se debe de abrir multiples paneles -->
  <v-expansion-panels v-model="panels" tile>
    <v-expansion-panel
      id="exp-panel"
      v-for="(item, i) in dataInsumo"
      :key="i"
      :readonly="item.estado"
    >
      <!-- agregar [ :class="{ 'secondary': panels.includes(i) }" ] si se debe de abrir multiples paneles -->
      <v-expansion-panel-header
        :class="{ 'secondary white--text': panels == i }"
      >
        <template v-slot:actions>
          <v-icon :class="{ 'white--text': panels == i }"> $expand </v-icon>
        </template>
        <div>
          <span>{{ item.codigo }} {{ item.nombre }}</span>
          <v-icon v-if="item.estado" class="px-6" color="green darken-2">
            mdi-check-circle-outline
          </v-icon>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="bgMinsal">
        <div
          class="d-flex mb-6 flex-column flex-md-row"
          color="grey lighten-2"
          flat
          tile
        >
          <div class="pa-2 mr-sm-auto mr-0 d-flex justify-center">
            <v-btn class="ma-2 text-capitalize" text color="secondary">
              <v-icon left dark> mdi-content-save </v-icon>
              Guardar Cambios
            </v-btn>
          </div>
          <div class="pa-2">
            <StatsMinComponent :info="itemsFondos" />
          </div>
        </div>
        <TableInsumosEPComponent :index="i" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import StatsMinComponent from "@/components/StatsMinComponent.vue";
import TableInsumosEPComponent from "./TableInsumosEPComponent.vue";
import { mapState } from "vuex";

export default {
  name: "ExpPanelDetalleComponent",
  components: { StatsMinComponent, TableInsumosEPComponent },
  data: () => ({
    panels: null,
    itemsFondos: [
      {
        id: 1,
        value: "$1,000.00",
        title: "Techo Asignado",
        color: "#309C6D",
      },
      {
        id: 2,
        value: "$50.00",
        title: "Fondo necesarios",
        color: "#FFEAA7",
      },
      {
        id: 3,
        value: "$953.00",
        title: "Saldo",
        color: "#309C6D",
      },
    ],
  }),
  computed: {
    ...mapState("solicitudesCompraModule", ["dataInsumo"]),
  },
};
</script>
<style lang="scss" scoped>
#exp-panel {
  border: 1.5px solid #111c4e;
}
.v-expansion-panel::before {
  box-shadow: none;
}

:deep(.v-expansion-panel-content__wrap) {
  padding: 15px;
}
</style>