<template>
  <section class="mb-12">
    <!-- Header Title -->
    <div class="my-6 d-flex text-center text-sm-left flex-column">
      <span class="text-h6 secondary--text"> 01-2022-S423 </span>
      <span class="text-h6 secondary--text">
        Solicitud de compra en línea de bienes o servicios
      </span>
      <span> Unidad de Retención de Talento Humano </span>
      <span> Ejercicio 2022 </span>
    </div>
    <!-- Actions Request -->
    <div class="d-flex justify-center flex-column justify-md-start flex-md-row">
      <v-btn
        elevation="0"
        class="text-capitalize"
        color="success"
        text
        @click="modalConfirmar = true"
      >
        <v-icon color="success" class="mr-2">mdi-check-circle-outline</v-icon>
        Aprobar solicitud
      </v-btn>
      <v-btn
        elevation="0"
        class="text-capitalize"
        color="error"
        text
        @click="modalCancelar = true"
      >
        <v-icon color="error" small class="mr-2">mdi-cancel</v-icon>
        Cancelar solicitud
      </v-btn>
      <v-btn elevation="0" class="text-capitalize" text v-if="false">
        <v-icon color="primary-text" small class="mr-2">
          mdi-message-reply-text
        </v-icon>
        Marcar con observación
      </v-btn>
    </div>
    <!-- Tab Container -->
    <v-tabs
      class="mt-4"
      :show-arrows="$vuetify.breakpoint.xs"
      align-with-title
      background-color="bgMinsal"
      v-model="tab"
    >
      <v-tab class="text-capitalize mx-0" v-for="item in items" :key="item.id">
        <v-icon left> {{ item.icon }} </v-icon>
        {{ item.name }}
      </v-tab>
      <v-tabs-items class="bgMinsal" v-model="tab">
        <v-tab-item>
          <div class="d-flex justify-center mt-6 flex-column">
            <div class="text-center">
              <v-btn
                elevation="0"
                class="text-capitalize"
                :text="!btnState"
                :outlined="btnState"
                @click="btnState = !btnState"
              >
                Fondo General
              </v-btn>
            </div>
            <StatsComponent :info="itemsFondos" />
          </div>
          <div class="mt-8 text-center text-md-left">
            <span class="text-body-2"> Listado Insumos </span>
            <ExpPanelDetalleComponent class="mt-6" />
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="mt-8">
            <tabla-proveedores-component :mostrarAprobarProveedores="3"/>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <div class="my-8">
      <router-link :to="{ name: 'list-solicitudes-compra', query: { space: 2 } }">
        <v-btn text><v-icon>mdi-arrow-left</v-icon> Volver</v-btn>
      </router-link>
    </div>
    <modal-confirmar
      :show="modalConfirmar"
      title="¿Desea aprobar la solicitud?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles"
      btnConfirmar="Aprobar"
      @close="closeModalConfirmar"
    />
    <modal-confirmar
      :show="modalCancelar"
      title="¿Desea cancelar la solicitud?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles"
      btnConfirmar="Aprobar"
      @close="closeModalCancelar"
    />
    <!-- 
      @confirm="aceptarEliminacion" -->
  </section>
</template>

<script>
import StatsComponent from "@/components/StatsComponent.vue";
import ExpPanelDetalleComponent from "./components/ExpPanelDetalleComponent.vue";
import modalConfirmar from "@/components/ConfirmationDialogComponent.vue";
import TablaProveedoresComponent from './components/TablaProveedoresComponent.vue';

export default {
  components: { StatsComponent, ExpPanelDetalleComponent, modalConfirmar, TablaProveedoresComponent },
  data: () => ({
    modalConfirmar: false,
    modalCancelar: false,
    tab: 0,
    btnState: false,
    items: [
      { id: 0, name: "Insumos", icon: "mdi-package-variant-closed" },
      { id: 1, name: "Proveedores", icon: "mdi-store-outline" },
    ],
    itemsFondos: [
      {
        id: 1,
        value: "$1,000.00",
        title: "Fondo general disponible",
        color: "#309C6D",
      },
      {
        id: 2,
        value: "$250.00",
        title: "Fondo necesarios para solicitud",
        color: "#FFEAA7",
      },
      {
        id: 3,
        value: "$750.00",
        title: "Saldo de fondos general",
        color: "#309C6D",
      },
    ],
  }),
  methods: {
    closeModalConfirmar() {
      this.modalConfirmar = false;
    },
    closeModalCancelar() {
      this.modalCancelar = false;
    },
  },
};
</script>