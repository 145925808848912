<template>
  <div class="">
    <v-data-table
      :headers="encabezadoProveedor"
      :items="proveedores"
      hide-default-footer
      no-data-text="No hay datos para mostrar"
    >
      <template v-slot:[`item.estado_proveedor`]="{ item }">
        <v-chip :color="getColor(item.estado_proveedor)" dark>
          <span :class="item.estado_proveedor!='Pendiente'?'white--text':'black--text'">
            {{ item.estado_proveedor }}
          </span>
        </v-chip>
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <router-link :to="{ name: 'verificar-proveedor-compra' }" v-if="mostrarAprobarProveedores == 2">
          <v-btn icon color="primary"><v-icon>mdi-clipboard-check-outline</v-icon></v-btn>
        </router-link>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'TableProveedoresComponent',
  props: {
    mostrarAprobarProveedores: String
  },
  data: () => ({
    proveedores: [
      {
        correlativo: 1,
        proveedor: 'Amazon',
        estado_proveedor: 'Pendiente',
        acciones: ''
      },
      {
        correlativo: 1,
        proveedor: 'Microsoft',
        estado_proveedor: 'Reprobado',
        acciones: ''
      },
      {
        correlativo: 1,
        proveedor: 'Sony',
        estado_proveedor: 'Aprobado',
        acciones: ''
      },
    ],
    encabezadoProveedor: [
      { text: 'Correlativo', align: 'center', value: 'correlativo' },
      { text: 'Nombre del proveedor', align: 'center', value: 'proveedor' },
      {
        text: 'Estado de proveedor',
        align: 'center',
        value: 'estado_proveedor',
      },
      {
        text: 'Acciones',
        align: 'center',
        value: 'acciones'
      }
    ],
  }),
  methods: {
    getColor(estado) {
      if (estado == 'Aprobado') return 'vigente'
      else if (estado == 'Pendiente') return 'pendiente'
      else return 'rechazado'
    },
  },
}
</script>

<style></style>
