<template>
  <v-row class="justify-center">
    <v-card
      color="bgMinsal"
      elevation="0"
      v-for="item in info"
      :key="item.id"
      class="mx-5 mt-1"
    >
      <v-card-title class="d-flex flex-column justify-center">
        <p class="text-h5">{{ item.value }}</p>
        <p class="my-n4 subtitle-2 text-center">{{ item.title }}</p>
      </v-card-title>
      <v-progress-linear
        :color="item.color"
        rounded
        value="100"
      />
    </v-card>
  </v-row>
</template>

<script>
export default {
  name: "StatsMinComponent",
  props: {
    info: {
      type: Array,
      required: true,
    },
  },
};
</script>